<template>
  <transition name="shrink" mode="out-in" appear>
    <a-layout id="components-layout-demo-top" class="layout">
      <a-layout-header style="background-color: transparent">
        <!-- <div class="logo">
          <img src="../assets/logo.png" />
        </div> -->
      </a-layout-header>
      <a-layout-content class="py-5 mb-5">
        <div class="container">
          <div class="row">
            <!-- Cover -->
            <div class="cover col-md-5 mb-4">
              <!-- <pre style="text-align: left; height: 1000px">{{JSON.stringify(origin, null, 2)}}</pre> -->
              <img class="image" src="../assets/cover.webp" />
              <h1 class="mt-5">Envíos Punto a Punto</h1>
              <p class="px-lg-4 fs-5 fw-lighter">Con Jipink P2P, solicitá envíos o devoluciones entre domicilios, de CABA o Gran Buenos Aires, sin moverte de tu casa.</p>
              by <img src="https://static.jipink.com/base/logo.png" width="80" />
            </div>
            <!-- Steps -->
            <div class="col-md-7">
              <a-card>
                <!-- Steps :: Header -->
                <template #title
                  v-if="steps.length > 1">
                  <div class="pt-4 d-none d-lg-block">
                    <a-steps
                      :current="index"
                      progress-dot>
                      <a-step v-for="step in steps" :key="step.key">
                        <template #title>{{ step.name }}</template>
                      </a-step>
                    </a-steps>
                  </div>
                </template>
                <!-- Steps :: Body -->
                <div id="step" class="p-2">
                  <transition name="slide-fadein-up" mode="out-in">
                    <!-- Origin -->
                    <div class="step" key="origin" v-if="step.key === 'origin'">
                      <p class="fs-5">¿Dónde retiramos?</p>
                      <p class="text-secondary">Indicanos en qué dirección debemos retirar, y a alguien a quien podamos contactar.</p>
                      <a-form-model
                        ref="origin"
                        :model="origin"
                        :rules="rules.origin">
                        <!-- Origin :: Address -->
                        <a-form-model-item prop="address">
                          <j-address
                            v-model="origin.address"
                            placeholder="Dirección de retiro"/>
                        </a-form-model-item>
                        <!-- Origin :: Address Apartment -->
                        <a-form-model-item prop="floor">
                          <j-floor
                            v-model="origin.address.apartment"
                            placeholder="Piso/Departamento" />
                        </a-form-model-item>
                        <!-- Origin :: Contact Name -->
                        <a-form-model-item prop="name">
                          <j-name
                            v-model="origin.name"
                            placeholder="Nombre de quien entrega"/>
                        </a-form-model-item>
                        <!-- Origin :: Contact Phone -->
                        <a-form-model-item prop="phone">
                          <j-phone
                            v-model="origin.phone"
                            placeholder="Teléfono de quien entrega"
                            has-feedback/>
                        </a-form-model-item>
                        <!-- Origin :: Contact Email -->
                        <a-form-model-item prop="email">
                          <j-email
                            v-model="origin.email"
                            placeholder="¿Cuál es tu email?"/>
                        </a-form-model-item>
                        <!-- Origin :: Address Type -->
                        <a-form-model-item>
                          <a-checkbox v-model="origin.business">
                            Retirar en horario laboral
                          </a-checkbox>
                        </a-form-model-item>
                      </a-form-model>
                    </div>
                    <!-- Destination -->
                    <div class="step" key="destination" v-else-if="step.key === 'destination'">
                      <p class="fs-5">¿Dónde entregamos?</p>
                      <p class="text-secondary">Indicanos en qué dirección debemos entregar tu envío, y quién es la persona que lo recibe.</p>
                      <a-form-model
                        ref="destination"
                        :model="destination"
                        :rules="rules.destination">
                        <!-- Destination :: Address -->
                        <a-form-model-item prop="address">
                          <j-address
                            v-model="destination.address"
                            placeholder="Dirección de destino" />
                        </a-form-model-item>
                        <!-- Destination :: Address Apartment -->
                        <a-form-model-item prop="floor">
                          <j-floor
                            v-model="destination.address.apartment"
                            placeholder="Piso/Departamento" />
                        </a-form-model-item>
                        <!-- Destination :: Conctact Name -->
                        <a-form-model-item prop="name">
                          <j-name
                            v-model="destination.name"
                            placeholder="Nombre del destinatario" />
                        </a-form-model-item>
                        <!-- Destination :: Contact Phone -->
                        <a-form-model-item prop="phone">
                          <j-phone
                            v-model="destination.phone"
                            placeholder="Teléfono del destinatario"
                            has-feedback/>
                        </a-form-model-item>
                        <!-- Shipment :: Notes -->
                        <a-form-model-item prop="notes">
                            <a-input
                              v-model="notes"
                              placeholder="¿Algo que quieras aclarar?"
                              size="large"
                              allow-clear>
                              <a-icon
                                slot="prefix"
                                type="message"
                                style="color: rgba(0,0,0,.25)" />
                            </a-input>
                        </a-form-model-item>
                        <!-- Destination :: Address Type -->
                        <a-form-model-item>
                          <a-checkbox v-model="destination.business">
                            Entregar en horario laboral
                          </a-checkbox>
                        </a-form-model-item>
                      </a-form-model>
                    </div>
                    <!-- Size -->
                    <div class="step" key="size" v-else-if="step.key === 'size'">
                      <!-- Content -->
                      <template v-if="features.require_content">
                        <p class="fs-5">¿Qué estás devolviendo?</p>
                        <a-input
                          v-model="content"
                          placeholder="Ejemplo: Teclado inalámbrico de PC"
                          size="large"
                          class="mb-4">
                          <a-icon slot="prefix" type="shopping" style="color: rgba(0,0,0,.25)" />
                        </a-input>
                      </template>
                      <!-- Size -->
                      <p class="fs-5">¿Qué tamaño tiene?</p>
                      <a-radio-group v-model="size" name="size">
                        <a-list item-layout="horizontal">
                          <!-- Size :: S -->
                          <a-list-item class="clickable" @click="size = 'S'">
                            <a-radio slot="actions" value="SMALL" />
                            <a-list-item-meta description="Ejemplos: tarjetas sd, celulares, cables, electronica portatil, fundas, accesorios, indumentaria, perfumeria, anteojos." >
                              <span slot="title">Pequeño <a-tag color="blue">Hasta 2 kg</a-tag></span>
                              <a-avatar slot="avatar" src="/icons/alarm-clock.png" shape="square" />
                            </a-list-item-meta>
                          </a-list-item>
                          <!-- Size :: M -->
                          <a-list-item class="clickable" @click="size = 'M'">
                            <a-radio slot="actions" value="MEDIUM" />
                            <a-list-item-meta description="Ejemplos: zapatillas, termos, impresoras de hogar, juguetes, herramientas, licuadoras o pequeños electrodomesticos." >
                              <span slot="title">Mediano <a-tag color="blue">Hasta 5 kg</a-tag></span>
                              <a-avatar slot="avatar" src="/icons/computer.png" shape="square" />
                            </a-list-item-meta>
                          </a-list-item>
                          <!-- Size :: L -->
                          <a-list-item class="clickable" @click="size = 'XL'">
                            <a-radio slot="actions" value="LARGE" />
                            <a-list-item-meta description="Ejemplos: calefones, estufas tiro balanceado, impresoras multifunción/oficina, escaleras plegables, sillas de comedor, sillas de oficina, sombrillas, articulos de jardin, fitness." >
                              <span slot="title">Grande <a-tag color="blue">Hasta 20 kg</a-tag></span>
                              <a-avatar slot="avatar" src="/icons/office-chair.png" shape="square" />
                            </a-list-item-meta>
                          </a-list-item>
                          <!-- Size :: XL -->
                          <a-list-item class="disabled">
                            <a-radio slot="actions" value="XLARGE" :disabled="true" />
                            <a-list-item-meta description="Ejemplos: heladeras, lavarropas, televisores, camas, sillones, calefones, aires acondicionados, armarios, alacenas, mesas, escaleras no plegables, bicicletas." >
                              <span slot="title">Muy grande <a-tag color="red">No Disponible</a-tag></span>
                              <a-avatar slot="avatar" src="/icons/sofa.png" shape="square" />
                            </a-list-item-meta>
                          </a-list-item>
                        </a-list>
                      </a-radio-group>
                    </div>
                    <!-- Payment -->
                    <div class="step" key="payment" v-else-if="step.key === 'payment'">
                      <p class="fs-5">¡Ya casi estamos!</p>
                      <p class="text-secondary">Pagá tu envío con Mercado Pago y quedará confirmado.</p>
                      <a-descriptions bordered>
                        <a-descriptions-item label="Origen" :span="3">
                          <div>{{origin.address.street}}</div>
                          <div class="text-secondary fw-lighter">{{ origin.address | city }}</div>
                        </a-descriptions-item>
                        <a-descriptions-item label="Destino" :span="3" v-if="destination.address">
                          <div>{{destination.address.street}}</div>
                          <div class="text-secondary fw-lighter">{{ destination.address | city }}</div>
                        </a-descriptions-item>
                        <a-descriptions-item label="Tarifa">
                          <div v-if="quotation" >
                            <a-statistic class="py-1" title="" :precision="2" :value="quotation.rate" prefix="$" />
                            <span class="text-muted fw-lighter">Retiramos {{ quotation.min_date | calendar }}.</span>
                          </div>
                          <div style="height: 65px" v-else-if="loading">
                            <a-skeleton :loading="loading" :active="true" :title="{width: 100}" :paragraph="false" />
                          </div>
                          <div v-else-if="isOutOfRange">
                            <a-statistic class="py-1" title="" :precision="2" :value="0" prefix="$" />
                            <span class="text-danger fw-lighter">Fuera del área de cobertura.</span>
                          </div>
                          <div v-else>
                            <a-statistic class="py-1" title="" :precision="2" :value="0" prefix="$" />
                            <span class="text-danger fw-lighter">No es posible cotizar en este momento.</span>
                          </div>
                        </a-descriptions-item>
                      </a-descriptions>
                      <p
                        class="mt-4"
                        style="font-size: 11px;">
                        Declaro y entiendo que el servicio a contratarse corresponde a una devolución de una compra realizada en Mercadolibre y cuento con el número de reclamo correspondiente brindado por Mercadolibre SRL. En caso de falsedad a lo recién declarado, el contratante será responsable de todos los mayores costos que esta operación pudiera tener y acepta mantener indemne a GOTRONICS SAS así como que GOTRONICS SAS no será responsable en caso de pérdida o rotura de los bienes transportados.
                      </p>
                      <a-checkbox
                        v-model="disclaimer">
                        Acepto los términos y condiciones enunciados aquí arriba
                      </a-checkbox>
                    </div>
                  </transition>
                </div>
                <!-- Steps :: Actions -->
                <a-space
                  class="float-end"
                  :size="10">
                  <a-button
                    type="link"
                    @click="onBack"
                    v-if="index > 0">
                    Atrás
                  </a-button>
                  <a-button
                    type="primary"
                    shape="round"
                    size="large"
                    @click="onNext"
                    v-if="steps.length - index > 1"
                    :disabled="!isActionEnabled">
                    Siguiente
                  </a-button>
                  <a-button
                    type="primary"
                    shape="round"
                    size="large"
                    @click="onSubmit"
                    v-if="steps.length - index === 1"
                    :loading="loading"
                    :disabled="!isActionEnabled">
                    Solicitar
                  </a-button>
                </a-space>
              </a-card>
            </div>
            <div class="detail">
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </transition>
</template>

<script>
import moment from 'moment'
moment.locale('es_AR')

import JAddress from '@/comp/address'
import JFloor from '@/comp/floor'
import JName from '@/comp/name'
import JPhone from '@/comp/phone'
import JEmail from '@/comp/email'

import rules from '../validators'

const steps = [
  {
    key: 'origin',
    name: 'Origen',
    enabled: f => f.require_origin,
    validator: ctx => ctx.$refs['origin'].validate,
    init: () => {},
    submit: ctx => ctx.request(),
    action: () => true,
  },
  {
    key: 'destination',
    name: 'Destino',
    enabled: f => f.require_destination,
    validator: ctx => ctx.$refs['destination'].validate,
    init: () => {},
    submit: ctx => ctx.request(),
    action: () => true,
  },
  {
    key: 'size',
    name: 'Tamaño',
    enabled: f => f.require_size,
    validator: () => fn => fn(true),
    init: () => {},
    submit: ctx => ctx.request(),
    action: ctx => ctx.size != null && ctx.content != null,
  },
  {
    key: 'payment',
    name: 'Pago',
    enabled: f => f.require_payment,
    validator: () => fn => fn(true),
    init: ctx => ctx.request(),
    submit: ctx => ctx.checkout.open(),
    action: ctx => ctx.disclaimer && ctx.checkout != null,
  },
]

export default {
  name: 'Form',
  props: {
    msg: String
  },
  components: {
    JAddress,
    JFloor,
    JName,
    JPhone,
    JEmail,
  },
  data: function () {
    return {
      rules,
      index: 0,
      notes: null,
      origin: {
        address: {},
      },
      destination: {
        address: {},
      },
      size: null,
      content: null,
      loading: false,
      error: null,
      quotation: null,
      checkout: null,
      disclaimer: false,
    }
  },
  created() {
    if (!this.$store.state.code) {
      this.$router.push('/')
    }
  },
  computed: {
    code() {
      return this.$store.state.code
    },
    features() {
      return this.$store.state.features
    },
    steps() {
      return steps.filter(s => s.enabled(this.features))
    },
    step() {
      return this.steps[this.index]
    },
    last() {
      return this.steps.length - this.index === 1
    },
    isActionEnabled() {
      return this.step.action(this)
    },
    isOutOfRange() {
      return this.error
        && this.error.response
        && this.error.response.data
        && this.error.response.data.code === 'OUT_OF_COVER'
    },
  },
  methods: {
    onBack() {
      this.index--
    },
    onNext() {
      this.step.validator(this)(valid => {
        if (valid) {
          this.index++
          this.step.init(this)
        }
      })
    },
    onSubmit() {
      this.step.validator(this)(valid => {
        if (valid) {
          this.step.submit(this)
        }
      })
    },
    request() {
      this.error = null
      this.loading = true
      const params = { code: this.code }
      // prepare the base payload
      const payload = {
        type: 'DELIVERY',
        method: 'EXPRESS',
        notes: this.notes,
      }
      // prepare the origin if required
      if (this.features.require_origin) {
        payload.origin = {
          address: {
            ...this.origin.address,
            type: this.origin.business ? 'BUSINESS' : 'RESIDENTIAL'
          },
          contact: {
            name: this.origin.name,
            phone: this.origin.phone,
            email: this.origin.email,
          },
        }
      }
      // prepare the destination if required
      if (this.features.require_destination) {
        payload.destination = {
          address: {
            ...this.destination.address,
            type: this.destination.business ? 'BUSINESS' : 'RESIDENTIAL'
          },
          contact: {
            name: this.destination.name,
            phone: this.destination.phone
          },
        }
      }
      // prepare the size if required
      if (this.features.require_size) {
        payload.size = this.size
      }
      // prepare the content if required
      if (this.features.require_content) {
        payload.content = this.content
      }
      this.$http.post('/self-service/shipments', payload, { params })
      .then(response => {
        if (response.data.payment) {
          // if payment is required, enable checkout
          this.quotation = response.data.quotation
          this.checkout = this.$mp.checkout({
            preference: {
                id: response.data.payment.external
            },
            theme: {
              elementsColor: '#DF3FB5',
            },
          })
        } else {
          // if payment is not required, redirect to tracking
          window.location.replace(response.data.url)
        }
      })
      .catch(error => {
        this.quotation = null
        this.checkout = null
        this.error = error
      })
      .finally(() => (this.loading = false))
    },
  },
}
</script>
<style lang="less" scoped>
.cover {
  text-align: center;
}
.cover .image {
  width: 80%;
}
.detail {
  max-width: 900px;
  margin: auto;
}
#components-layout-demo-top .logo {
  width: 120px;
  height: 35px;
  margin: 16px 24px 16px 0;
  float: left;
}
#components-layout-demo-top .logo img {
  height: 100%;
}
h1 {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 1;
  color: #303669;
}
.clickable {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.clickable:hover .ant-list-item-meta-title {
    color: @primary-color;
}
</style>
